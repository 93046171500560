import {Component} from 'react';
import {base_site, stripe_public_key} from './constants';
import { loadStripe } from "@stripe/stripe-js";
import {CardElement, Elements, ElementsConsumer} from "@stripe/react-stripe-js";

import {Button, Container} from "@material-ui/core";
import Notify from "../utils/Notify";
import http from "../api-service/http-common";

const stripePromise = loadStripe(stripe_public_key);
const cardStyle = {

    style: {

        base: {

            color: "#32325d",

            fontFamily: 'Courier, monospace',

            fontSmoothing: "antialiased",

            fontSize: "16px",

            "::placeholder": {

                color: "#32325d"

            }

        },

        invalid: {

            color: "#fa755a",

            iconColor: "#fa755a"

        }

    }

};
class CreatePaymentMethod extends Component{
    constructor(props) {

        super();

        this.state = {
            'disabled': false,
            'error': '',
        }
    }
    componentDidMount() {

    }

    handlePaymentClick = async (event) => {
        // Get Stripe.js instance
        const stripe = await stripePromise;
        const cardElement = this.props.elements.getElement(CardElement);
        Notify.showLoading();
        // Call your backend to create the Checkout Session
        event.preventDefault();
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        http.post(base_site+'/payments/add-payment-method/', {
            product: 'prod_J9eN5D758WXRum',
            payment_method: paymentMethod,

        }).then(res => {
            Notify.notifySuccess('Method Added Successfully', false)
            if(this.props.on_add)
                this.props.on_add(paymentMethod);

        }).catch(error => {
            if (error.data)
                Notify.notifyError(error.data);
            else if (error.error)
                Notify.notifyError(error.error);
        });


        // When the customer clicks on the button, redirect them to Checkout.


        if (error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        }
    };
    handleChange = async (event) => {

        // Listen for changes in the CardElement

        // and display any errors as the customer types their card details

        this.setState(
            {
                disabled: event.empty,
                error: event.error ? event.error.message : "",
            });



    };
    render() {
        return (
            <Container style={{minWidth: '20em'}}>

                <form>
                Payment Part
                <br/>
                <CardElement
                    options={{
                        hidePostalCode: true,
                    }}
                    id="card-element"  />
                <br/>
                <Button role="link" onClick={this.handlePaymentClick}> Add method </Button>
                </form>
            </Container>
        )
    }
}
const InjectedCheckoutForm = (props) => {
    return (
        <Elements stripe={stripePromise}>
        <ElementsConsumer>
            {({elements}) => (
                <CreatePaymentMethod {... props} elements={elements} />
            )}

        </ElementsConsumer>
        </Elements>
    );
};
export default InjectedCheckoutForm
