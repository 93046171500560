import React, {Component} from "react";
// eslint-disable-next-line no-unused-vars
import {
    Container,
    Tab,
    Tabs,
    Card,
    Button,
    CardTitle,
    CardText,
    Grid,
    AppBar,
    Box, MobileStepper
} from "@material-ui/core";

import './css/choos_your_plan.css';
import ChooseYourBook from "./choose-books";
import ChooseYourBookExtras from "./choose-your-book-extras";
import NameCharacter from "./name-character";
import ChooseCharacter from "./choose-character";
import ReadyToCheckout from "./ready_to_checkout";
import {base_site, site_urls} from "./constants";
import { withRouter } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {withStyles} from "@material-ui/core";
import './css/fonts/poppins.css';
import Notify from "../utils/Notify";
import {connect} from "react-redux";
import StepOne from './ChoosePlan';
import http from "../api-service/http-common";
import ShippingAddressForm from "./shipping_address";
import api from "../api-service/api";
import ThankYou from "./steps/thank-you/thank-you";

const constants_url = '/api/constants/';

const styles = ((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    actionsContainer: {
        marginTop: theme.spacing(1),
    }
}));


function getSteps() {
    return [

        'Choose Plan Type',
        'Choose Book',
        'Choose Book Extras',
        'Name Character',
        'Choose Character',
        'Shipping Address',
        'Checkout',
    ];
}

class Process extends Component {

    constructor(props) {

        super(props);
        const formData = localStorage.getItem('formData');
        if (formData) {
            this.form_data = JSON.parse(formData);
        } else {
            this.form_data = {
                'book_type': 'paperback',
                payment_type: null,
                book_props: {},
                character_options: {},
                books: [],
                address: {},
                child: {gender: 'boy'},
                preferred_days: [1, 15],
                postage_per_book: 0,
                disabledBooks: [],
            };

        }

        this.books = [];
        this.state = {
            currentStep: 0,
            active_tab: 0,
            next_button_disabled: true,
            loading: false,
            show_modal: false,
            modal_body: '',
            is_product_modal: false,

        };
        this.one_time_url = base_site + '/api/start-subscription/';
        this.pay_as_go_url = base_site + '/api/start-monthly-subscription/';
        this.result_data = {}
        // console.log(this.start_url);

    }

    componentDidMount() {
        // TODO: add a get method to get last formdata of customer.

        http.get(constants_url).then(res => {
            this.form_data.postage_per_book = parseFloat(res.data.filter(cons => cons.meta_key === 'postage_price_book')[0].meta_value);
        })

    }

    modal_close_click = event => {
        if (this.state.is_product_modal)
            this.props.history.push(site_urls.subscription_list);
        this.setState({show_modal: false, is_product_modal: false})

    }
    handle_form_change = event => {
        const {name, value} = event.target;

        this.form_data[name] = value;
        localStorage.setItem('formData', JSON.stringify(this.form_data))
        console.log(this.form_data);

    };
    handle_state_change = target => {
      this.setState(target);
    };
    next = () => {
            this.setState({currentStep: this.state.currentStep + 1, next_button_disabled: true})
    };
    previous = () => {
        this.setState({currentStep: this.state.currentStep-1})
    };
    finish_payment = (event) => {
        Notify.showLoading();
        const {plan_name} = this.form_data;
        let end_url = this.pay_as_go_url;
        // if (plan_name === "Pay As You Go") {
        //     end_url = this.pay_as_go_url;
        // } else {
        //     end_url = this.one_time_url;
        // }

        this.start_subscription(end_url).then(res => {
            Notify.notifySuccess('Subscribed Successfully');
        }).catch(err => {
            Notify.notifyError(err.response.data.error);
        });
    }
    start_subscription = async (end_url) => {
        this.setState({loading: true});
        const {
            plan,
            coupon_code,
            payment_method_id,
            book_props,
            address,
        } = this.form_data;
        let subscription_data = {
            plan_id: plan,
            coupon_code,
            payment_method_id,
            'book_type': this.form_data['book_type'],
        }
        let child_data = this.form_data.child;
        child_data.character = JSON.stringify(this.form_data.character_options);
        await api.Child.post(child_data).then((res) => {

                subscription_data.child_id = res.data.id;
            }
        );
        await api.Address.post(address).then((res) => {

                subscription_data.address_id = res.data.id;
            }
        );
        // Removed permanently one time subscription
        Notify.notifyInfo('Adding Subscription', 'loading')
        let subscription_id;
        await api.Subscription.start_monthly(subscription_data).then((res) => {
            this.setState({
                loading: false,
                show_modal: true,
                modal_body: JSON.stringify(res.data),
                is_product_modal: true
            });
            subscription_id = res.data.obj_id
        });
        Notify.notifyInfo('Adding Books', 'loading')
        const booksResponse = this.form_data.books.map(async (book, index) => {
            let book_prop = this.form_data.book_props[book.id];
            if (book_prop === undefined) {
                book_prop = {};
            }
            const {giftbox, message, message_text, image} = book_prop;
            let book_data = {book: book.id, subscription: subscription_id, order: index}
            book_data.book_props = JSON.stringify({giftbox, message, message_text, image})
            console.log(book_data)
            await api.Book.post(book_data)
        })
        console.log(booksResponse);

        localStorage.setItem('formData', JSON.stringify({}))
        Notify.notifySuccess("Successfully Subscribed");
        setTimeout(() => {

            window.location.pathname = site_urls.thankYou.url.replace(':id', subscription_id);

        }, 3000)
    }
    steps = () => {
        switch
            (this.state.currentStep+1) {
            case 1:
                return (
                           <StepOne handle_state_change={this.handle_state_change} handle_change={this.handle_form_change} form_data={this.form_data}/>
                );
            case 2:
                return (
                    <ChooseYourBook handle_state_change={this.handle_state_change} handle_change={this.handle_form_change} form_data={this.form_data}/>
                );
            case 3:
                return (
                    <ChooseYourBookExtras handle_state_change={this.handle_state_change} handle_change={this.handle_form_change} book_ids={this.state.books} form_data={this.form_data}/>
                );
            case 4:
                return (
                    <NameCharacter handle_state_change={this.handle_state_change}
                                   handle_change={this.handle_form_change} book_ids={this.state.books}
                                   form_data={this.form_data}/>
                );
            case 5:
                return (
                    <ChooseCharacter handle_state_change={this.handle_state_change}
                                     handle_change={this.handle_form_change} book_ids={this.state.books}
                                     form_data={this.form_data}/>
                );
            case 6:
                return <ShippingAddressForm handle_state_change={this.handle_state_change}
                                            handle_change={this.handle_form_change} book_ids={this.state.books}
                                            form_data={this.form_data}/>
            case 7:
                return (
                    <ReadyToCheckout handle_state_change={this.handle_state_change}
                                     handle_change={this.handle_form_change} book_ids={this.state.books}
                                     form_data={this.form_data}/>
                );
            default:

                return (
                    <StepOne handle_state_change={this.handle_state_change} handle_change={this.handle_form_change}/>

                );

        }
    };
    render() {
        var steps = getSteps();
        // const classes = useStyles();
        const {classes} = this.props;
        if (!this.props.auth.isAuthenticated){
            Notify.notifyInfo('You are not logged in, register or login to continue', '', false, 'info');
        }
        return(
            <>
                <div id={'main'} className={classes.root}>
                        <Box  style={{margin: '1em', marginBottom: "4em"}}>{this.steps()}</Box>
                        <Grid container xs={12} align="center" justify = "center" alignItems = "center" className={classes.actionsContainer}>
                                <Grid item xs={8}>
                                <MobileStepper
                                    variant="dots"
                                    steps={6}
                                    position="bottom"
                                    activeStep={this.state.currentStep}
                                    backButton={<Button
                                        disabled={this.state.currentStep === 0}
                                        onClick={this.previous}
                                        className={classes.button}
                                    >
                                        Back
                                    </Button>}
                                    nextButton={<Grid item>

                                        {this.state.currentStep === steps.length - 1 ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.finish_payment}
                                                className={classes.button}
                                                disabled={this.state.next_button_disabled}
                                            >
                                                Finish
                                            </Button>

                                            :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.next}
                                                className={classes.button}
                                                disabled={!(!this.state.next_button_disabled && this.props.auth.isAuthenticated)}
                                            >
                                                Next
                                            </Button>

                                        }

                                    </Grid>}
                                />
                                </Grid>
                        </Grid>

                    {this.currentStep === steps.length && (
                        <Paper square elevation={0} className={''}>
                            <Typography>All steps completed - you&apos;re finished</Typography>
                            <Button  className={classes.button}>
                                Reset
                            </Button>
                        </Paper>
                    )}
                </div>
            </>
        )

    }

    nextButton() {
        console.log(this.state.currentStep);
        return (
            this.state.currentStep !== 7 ? (
                <Button onClick={this.next} style={{marginLeft: '1em'}}
                        disabled={this.state.next_button_disabled}>Next</Button>
            ) : (
                (this.state.loading ? (<spinner animation="border"/>) : (
                    <Button onClick={this.start_subscription} type={'submit'} style={{marginLeft: '1em'}}
                            disabled={this.state.next_button_disabled}>Add to basket</Button>
                ))
            )
        )
    }

    previousButton() {
        return (
            <Button onClick={this.previous}>Previous</Button>
        )
    }
}


const mapStatetoProps = state =>({
    auth: state.auth
})

export default withStyles(styles, {withTheme: true})(connect(mapStatetoProps)(withRouter(Process)));
