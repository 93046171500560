import React from "react";
import {Box, Grid} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import midImg from "./image-mid.svg";
// import bottomImg from "./bottom.svg";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-PKQGWFR'
}
TagManager.initialize(tagManagerArgs)

const bottomImg = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgdmlld0JveD0iMCAwIDEyODAgODYiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIHNsaWNlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0xMjgwIDYzLjFjLTMuOCAwLTcuNi4zLTExLjQuOC0xOC4zLTMyLjYtNTkuNi00NC4yLTkyLjItMjUuOS0zLjUgMi02LjkgNC4zLTEwIDYuOS0yMi43LTQxLjctNzQuOS01Ny4yLTExNi42LTM0LjUtMTQuMiA3LjctMjUuOSAxOS4zLTMzLjggMzMuMy0uMi4zLS4zLjYtLjUuOC0xMi4yLTEuNC0yMy43IDUuOS0yNy43IDE3LjUtMTEuOS02LjEtMjUuOS02LjMtMzcuOS0uNi0yMS43LTMwLjQtNjQtMzcuNS05NC40LTE1LjgtMTIuMSA4LjYtMjEgMjEtMjUuNCAzNS4yLTEwLjgtOS4zLTI0LjMtMTUtMzguNS0xNi4yLTguMS0yNC42LTM0LjYtMzgtNTkuMi0yOS45LTE0LjMgNC43LTI1LjUgMTYtMzAgMzAuMy00LjMtMS45LTguOS0zLjItMTMuNi0zLjgtMTMuNi00NS4yLTYxLjUtNzEuMS0xMDctNTcuNkE4Ni4zOCA4Ni4zOCAwIDAgMCA1MzguNiAzM2MtOC43LTMuNi0xOC43LTEuOC0yNS40IDQuOC0yMy4xLTI0LjgtNjEuOS0yNi4yLTg2LjctMy4xLTcuMSA2LjYtMTIuNSAxNC44LTE1LjkgMjQtMjYuNy0xMC4xLTU2LjktLjQtNzIuOCAyMy4zLTIuNi0yLjctNS42LTUuMS04LjktNi45LS40LS4yLS44LS40LTEuMi0uNy0uNi0yNS45LTIyLTQ2LjQtNDcuOS00NS44LTExLjUuMy0yMi41IDQuNy0zMC45IDEyLjUtMTYuNS0zMy41LTU3LTQ3LjQtOTAuNS0zMS0yMiAxMC44LTM2LjQgMzIuNi0zNy44IDU3LjEtNy0yLjMtMTQuNS0yLjgtMjEuOC0xLjYtMTQtMjEuNy00My4xLTI3LjktNjQuOC0xMy44LTUuNiAzLjYtMTAuMyA4LjQtMTMuOSAxNEMxMy41IDY0IDYuOCA2My4yIDAgNjMuMi0uMSA2My4yIDAgODYgMCA4NmgxMjgwVjYzLjF6Ii8+PC9nPjwvc3ZnPg==';

class ThankYou extends React.Component {
    constructor(props) {
        super(undefined);
        this.pk = parseInt(props.match.params.id, 10);

        this.state = {
            subscription: null
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <Container justify="center" >
                <Grid container spacing={3} justify="center"
                      style={{
                          textAlign: "center", width: '', marginBottom: '3em',
                          marginTop: '5em',
                      }}>
                    <Grid item xs={12} justify="center" justifyContent={'center'}>
                        <Typography
                            style={{

                                color: '#55c0df',
                                fontSize: '49px',
                                lineHeight: '53.9px',
                                fontWeight: 800

                        }}>Thank you for your purchase!</Typography>
                    </Grid>
                    <Grid item xs={12} justify="center" justifyContent={'center'}>
                        <Typography
                            style={{
                                color: '#fac5a8',
                                fontSize: '28.5px',
                                lineHeight: '28.5px',
                                fontWeight: 500
                                    }}>Every purchase helps us to grow our business and invest in
                            encouraging children to love reading!</Typography>
                    </Grid>
                </Grid>
                <br/>
                <Grid container  justify="center"
                      style={{
                          textAlign: "center",
                          backgroundColor: "#f9e8a4",
                          width: '100%',

                      }}>

                    <img src={midImg} />
                    <Grid item xs={12} justify="center" justifyContent={'center'}>
                        <Typography
                            style={{

                                color: '#55c0df',
                                fontSize: '36px',
                                lineHeight: '50.4px',
                                fontWeight: 600

                            }}>We know you will love our books!</Typography>
                    </Grid>

                    <Grid item xs={12} justify="center" justifyContent={'center'}>
                        <Typography

                            style={{

                                fontSize: '16px',
                                lineHeight: '28.8px',
                                fontWeight: 400

                            }}>So when you place your next order, be sure to use the exclusive
                            discount code in your confirmation email</Typography>
                    </Grid>

                    <img src={bottomImg}/>
                </Grid>

            </Container>

        );
    }
}

export default ThankYou
