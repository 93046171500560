import axios from "axios";
import {base_site} from "../components/constants";
let http = axios.create({
    baseURL: base_site,
    withCredentials: true
});

http.interceptors.request.use((config) => {
    // console.log(config);
    config.headers.common['TEST'] = '43908fds90a8fds092;';
    return config
})
export default http
