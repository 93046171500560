import { red } from '@material-ui/core/colors';
import poppins from './assets/fonts/Poppins/Poppins-Black.ttf';
import {createTheme} from '@material-ui/core/styles';
// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#fff7dc',

        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#e4f9f8',
        },
        type: 'light',

    },
    typography: {
        fontFamily: poppins,
        lineHeight: 2,
        margin: 2,
        subtitle1: {
            fontFamily: poppins,
            color: '#55c0df',
        },
        button: {
            color: '#55c0df',
            backgroundColor: '#fff7dc',
            fontStyle: "italic",
            error: {
                color: red.A400,
            },

        },

    },
    headlines: {
        headline: {
            fontFamily: poppins,
            color: '#55c0df'
        },
        subHeadline: {
            fontFamily: poppins,
            color: '#fac5a8'
        }
    }

});

export default theme;
