import {Component} from "react";
import {Button, Container, Grid, Table} from "@material-ui/core";
import {Link} from "react-router-dom";
// import Link from '@material-ui/core/Link';
import Pricing from "./pricing";

import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {site_urls} from "./constants";
// import Link from '@material-ui/core/Link';
import step0 from "../assets/imgs/step_0.jpg";
import step1 from "../assets/imgs/step_1.jpeg";
import step2 from "../assets/imgs/step_2.jpeg";
import step3 from "../assets/imgs/step_3.jpeg";
import CustomizedDialogs from "./modal-template";

function Copyright() {
    return (
        <Typography variant="h4" color="textSecondary" align="center">
            Books are shipped on the 10th & 25th of every month<br/><br/>
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        // backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(0, 0, 5),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}));


const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

function Home() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <main>
                {/* Hero unit */}
                <Container className={classes.cardGrid} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container spacing={4} justify={"center"}>
                        <Grid item key="0" xs={12} sm={10} md={8}>
                            <Card className={classes.card}>
                                <CardMedia
                                    image={step0}
                                    title="Image title"
                                    style={{padding: '37.50%'}}
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="h5" component="h2">

                                    </Typography>
                                    <Typography align={"center"}>
                                        UK's First & Only Range Of Super Personalised Books, Where You Choose The Books
                                        And Are Delivered Direct To Your Door
                                    </Typography>
                                </CardContent>
                            </Card>

                        </Grid>
                    </Grid>
                    <Grid container spacing={4} justify={"center"}>

                        <Grid item key="1" xs={11} sm={6} md={4}>
                            <Card className={classes.card}>
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={step1}
                                    title="Image title"
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="subtitle1" component="h2">
                                        Choose Your Plan
                                    </Typography>
                                    <Typography>
                                        Find the subscription plan that suits you
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item key="2" xs={11} sm={6} md={4}>
                            <Card className={classes.card}>
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={step2}
                                    title="Image title"
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="subtitle1" component="h2">
                                        Select Your Books
                                    </Typography>
                                    <Typography>
                                        Choose from our exclusive collection of exciting stories.
                                    </Typography>
                                </CardContent>

                            </Card>
                        </Grid>
                        <Grid item key="3" xs={11} sm={6} md={4}>
                            <Card className={classes.card}>
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={step3}
                                    title="d"
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="subtitle1" component="h2">
                                        Create Your Character
                                    </Typography>
                                    <Typography>
                                        Create the perfect character for your loved one to appear in your chosen
                                        stories!
                                    </Typography>
                                    </CardContent>

                                </Card>
                            </Grid>
                    </Grid>
                </Container>
                <div className={classes.heroContent}>
                    <Container maxWidth="sm">

                        <div className={classes.heroButtons}>
                            <Grid container spacing={2} justify="center">

                                <Link
                                    to={site_urls.start_process.url.replace(':planSlug', 'newborn')}
                                    component={Button} variant="contained"
                                    color="primary">
                                    Let's Start
                                </Link>
                            </Grid>
                        </div>
                    </Container>
                </div>
            </main>
            {/* Footer */}
        </React.Fragment>
    );
}

export default Home;
