import React, {Component} from "react";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid, IconButton,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import DehazeIcon from '@material-ui/icons/Dehaze';
import {withStyles} from "@material-ui/core/styles";
import Api from "../../api-service/api";
import notify from "../../utils/Notify";
import ImageGiftBox from "../book-extras";
import CustomizedDialogs from "../modal-template";
import http from "../../api-service/http-common";
import api from "../../api-service/api";
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';


const constants_url = '/api/constants/';


// fake data generator
const getItems = count =>
    Array.from({length: count}, (v, k) => k).map(k => ({
        pk: `item-${k}`,
        book_name: `item ${k}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    marginBottom: '5px',

});

class BookList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
        this.onDragEnd = this.onDragEnd.bind(this);
        this.formValues = {book_props: {}}
    }

    componentDidMount() {
        this.setState({
            items:
            // getItems(4)})
            this.props.subscription.books
        })

        http.get(constants_url).then(res => {
            this.formValues.postage_per_book = parseFloat(res.data.filter(cons => cons.meta_key === 'postage_price_book')[0].meta_value);
        })
        this.formValues.price = parseFloat(this.props.subscription.price);

        this.props.subscription.books.map((book, index) => {
            if (book.status == 'pending') {
                this.formValues.book_props[book.pk] = book.props
            }
        })

        api.Plan.retrieve({id: this.props.subscription.plan})
        console.log(this.formValues)
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });
    }

    updateSorting() {

    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity

    updateClicked = async () => {
        notify.showLoading();
        await Promise.all(this.state.items.map(async (book, index) => {
                    if (book.status == 'pending') {
                        return await Api.Book.update({id: book.pk, order: index}).catch(err => err.response.json)
                    }
                }
            )
        )

        notify.notifySuccess('Updated');

    };

    updateForm = event => {
        const {name, value} = event.target;
        // eslint-disable-next-line no-unused-vars
        this.formValues.book_props[name] = value;
        this.setState({})
        console.log(this.formValues);
        // this.props.form_data['book_props'] = book_props;
        // console.log(this.props.form_data);
    };

    updateBook = (id) => async (event) => {
        const {giftbox, message, message_text, image} = this.formValues.book_props[id]
        let book_data = {id: id,}
        book_data.book_props = JSON.stringify({giftbox, message, message_text, image})
        notify.showLoading();
        await api.Book.update(book_data)
        notify.notifySuccess("Updated")
    }
    deleteBook = (id) => async (event) => {
        notify.showLoading();
        await api.Book.delete({id})
        notify.notifyError('Deleted')

    }

    render() {

        const {classes} = this.props;

        return (
            <TableContainer class={classes.table}>
                <DragDropContext onDragEnd={this.onDragEnd}>

                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.headerCell}></TableCell>
                                            <TableCell className={classes.headerCell}>ID</TableCell>
                                            <TableCell className={classes.headerCell}>Book Name</TableCell>
                                            <TableCell className={classes.headerCell}>Status</TableCell>
                                            <TableCell className={classes.headerCell}>Amount Paid</TableCell>
                                            <TableCell className={classes.headerCell}>Process Date</TableCell>
                                            <TableCell className={classes.headerCell}>Approximate Delivery
                                                Date</TableCell>
                                            <TableCell className={classes.headerCell}>Book Extra's</TableCell>
                                            <TableCell className={classes.headerCell}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {this.state.items.map((book, index) => {

                                            return (
                                                <React.Fragment>
                                                    {book.status === 'pending' ?
                                                        <Draggable key={book.pk} draggableId={book.pk.toString()}
                                                                   index={index}>
                                                            {(provided, snapshot) => (
                                                                <TableRow ref={provided.innerRef}
                                                                          style={getItemStyle(
                                                                              snapshot.isDragging,
                                                                              provided.draggableProps.style
                                                                          )}
                                                                >
                                                                    <TableCell
                                                                        ref={provided.innerRef}
                                                                        {...provided.dragHandleProps}

                                                                        {...provided.draggableProps}
                                                                    >
                                                                        <DehazeIcon/>
                                                                    </TableCell>
                                                                    <TableCell>{book.book}</TableCell>
                                                                    <TableCell>{book.book_name}</TableCell>
                                                                    <TableCell>{book.status}</TableCell>
                                                                    <TableCell>{Math.round(parseFloat(book.paid) / parseFloat(book.price) * 100, 2)} %</TableCell>
                                                                    <TableCell>{book.process_date}</TableCell>
                                                                    <TableCell>{book.delivery_date}</TableCell>
                                                                    <TableCell>{
                                                                        book.props ?
                                                                            <Grid container>
                                                                                <FormControlLabel disabled
                                                                                                  control={<Checkbox
                                                                                                      checked={book.props.giftbox}
                                                                                                      name="checkedE"/>}
                                                                                                  label="Giftbox"/>
                                                                                <FormControlLabel disabled
                                                                                                  control={<Checkbox
                                                                                                      checked={book.props.message}
                                                                                                      name="checkedE"/>}
                                                                                                  label="Message"/>
                                                                                {book.props.message ?
                                                                                    <Button
                                                                                        component={'a'}
                                                                                        href={book.props.image}
                                                                                        target='_blank'
                                                                                        size='small'
                                                                                        variant={'contained'}
                                                                                        color={'secondary'}
                                                                                    >
                                                                                        Open Image
                                                                                    </Button>
                                                                                    : ''
                                                                                }
                                                                            </Grid>
                                                                            : ''
                                                                    }</TableCell>
                                                                    <TableCell>
                                                                        <CustomizedDialogs title={<EditTwoToneIcon/>}>
                                                                            <ImageGiftBox book={book}
                                                                                          form_data={this.formValues}
                                                                                          update_form={this.updateForm}/>
                                                                            <Button color={"primary"}
                                                                                    onClick={this.updateBook(book.id)}>Update</Button>
                                                                        </CustomizedDialogs>
                                                                        <IconButton
                                                                            onClick={this.deleteBook(book.id)}><DeleteOutlineTwoToneIcon/></IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}

                                                        </Draggable> :
                                                        <TableRow
                                                        >
                                                            <TableCell></TableCell>
                                                            <TableCell>{book.book}</TableCell>
                                                            <TableCell>{book.book_name}</TableCell>
                                                            <TableCell>{book.status}</TableCell>
                                                            <TableCell>{Math.round(parseFloat(book.paid) / parseFloat(book.price) * 100, 2)} %</TableCell>
                                                            <TableCell>{book.process_date}</TableCell>
                                                            <TableCell>{book.delivery_date}</TableCell>
                                                            <TableCell>{
                                                                book.props ?
                                                                    <Grid container>
                                                                        <FormControlLabel disabled control={<Checkbox
                                                                            checked={book.props.giftbox}
                                                                            name="checkedE"/>}
                                                                                          label="Giftbox"/>
                                                                        <FormControlLabel disabled control={<Checkbox
                                                                            checked={book.props.message}
                                                                            name="checkedE"/>}
                                                                                          label="Message"/>
                                                                        {book.props.message ?
                                                                            <Button
                                                                                component={'a'}
                                                                                href={book.props.image}
                                                                                target='_blank'
                                                                                size='small' variant={'contained'}
                                                                                color={'secondary'}
                                                                            >
                                                                                Open Image
                                                                            </Button>
                                                                            : ''
                                                                        }
                                                                    </Grid>
                                                                    : ''
                                                            }</TableCell>

                                                        </TableRow>}
                                                </React.Fragment>

                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <Grid item>
                    <Button fullWidth color={'secondary'} onClick={this.updateClicked}>Update</Button>
                </Grid>

            </TableContainer>
        );
    }
}


const styles = (theme => ({
    root: {
        minWidth: 275,
        marginTop: theme.spacing(5),
    },

    title: {
        fontSize: 14,
    },
    headerCell: {
        fontWeight: 700,
    },
    table: {
        marginTop: theme.spacing(4),
    }
}));


export default withStyles(styles, {withTheme: true})(BookList);
