function shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

const Books = {
    stories: [
        {id: 1, name: "This Series is about your child going on adventures as a spy!"},
        {id: 2, name: "The crown Guard"},
        {id: 3, name: "The missing amulet of bigfoot"},
        {id: 4, name: "The Spy games"},
        {id: 5, name: "The Biscuit of thief Captor"},
    ],
    get_book_by_theme: function (theme) {
        return shuffle(this.stories);
    }
};

export default Books
