import React, {Component} from "react";
import Plans from "./demo_data/plans";
import {
    AppBar,
    Box,
    Grid,
    RadioGroup,
    Tab,
    Tabs,
    Typography,
    withStyles,
    Switch,
    FormControlLabel
} from "@material-ui/core";
import PlansInfo from "./PlanInfo";
import * as PropTypes from "prop-types";
import {PricingCard,} from "./pricing";
import Pricing from "./pricing";
import {groupBy} from "./constants";
import Container from "@material-ui/core/Container";
import http from "../api-service/http-common";
import {yellow} from "@material-ui/core/colors";
import {withRouter} from "react-router-dom";


function Item(props) {
    const {sx, ...other} = props;
    return (
        <Box
            sx={{
                bgcolor: 'primary.main',
                color: 'white',
                p: 1,
                borderRadius: 1,
                textAlign: 'center',
                fontSize: '1rem',
                fontWeight: '700',
                ...sx,
            }}
            {...other}
        />
    );
}

Item.propTypes = {
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
const styles = (theme) => ({
    choosePlanContainer: {
        backgroundColor: yellow.A700,
    },
    singlePlan: {
        backgroundColor: yellow.A400
    }
})

class StepOne extends Component {
    constructor(props) {
        super(props);
        const {plan} = props.form_data;
        this.state = {
            active_tab: 0,
            plans: [],
            plan,

        };
    }
    componentDidMount() {
        this.form_validator();
        this.get_plans();

    }
    get_plans = () => {

        const subplan = (this.props.match.params.planSlug);

        http.get('/subscriptions/api/plans/', {
            params: {'subplan': subplan}
        }).then(res => {
            // console.log(res.data.results);
            this.setState({plans: res.data.results});
        }).catch(err => {
            // console.log(err)
        })
    }
    toggle_tab = (tab,value) => {
        console.log(tab);
        console.log(value);
        if(this.state.active_tab!==tab){
            this.setState({
                active_tab: value
            });
            this.props.handle_change({target: {
                    name: 'book_type',
                    value: tab,
                }});

        }
    };
    form_validator = event => {
        // this.props.handle_change(event);

        if (this.props.form_data.plan && this.props.form_data.book_type) {
            this.props.handle_state_change({
                next_button_disabled: false,
            })
        } else {
            this.props.handle_state_change({
                next_button_disabled: true,
            })
        }
    };
    plan_updated = event => {
        const {name, value} = event.target;
        let obj = {};
        obj[name] = (value.id);
        // const [plan, monthly] = value.split('-');
        const p = value;
        this.props.form_data.plan = p.id;
        this.props.form_data.price = parseFloat(p.price);
        this.props.form_data.plan_name = p.name;
        this.props.form_data.subplan = p.subplan;
        this.props.form_data.books_included = p.books_included;
        this.props.form_data.books = [];
        this.setState(obj);
        this.form_validator(event);
        console.log(this.props.form_data);
    }
    bookTypeUpdated = event => {
        const {name, value} = event.target;
        this.props.handle_change(event);
        this.form_validator();
    }

    render() {
        const {classes} = this.props
        const grouped = groupBy(this.state.plans.filter(plan => plan.payment_type), plan => plan.name);
        return (
            <React.Fragment>
                <Container maxWidth="lg" component="main">
                    <RadioGroup>
                        <Grid container spacing={5} alignItems="flex-end">
                            {
                                [...grouped].map(name => {
                                const [type, items] = name;
                                    return <PricingCard tier={items} book_type={this.props.form_data.book_type}
                                                        onChange={this.plan_updated}
                                                        bookTypeUpdated={this.bookTypeUpdated} name={'plan'}
                                                        value={this.state.plan}/>;

                            })
                        }
                    </Grid>
                </RadioGroup>
            </Container>

        </React.Fragment>
        );
    }
}

export default withStyles(styles)(withRouter(StepOne));
