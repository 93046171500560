import React, {Component, Fragment} from "react";
import {Box, Container, Grid, withStyles,} from "@material-ui/core";
import {FormControl, FormLabel, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import './css/choose_character.css'
import {base_site, convertToSlug} from "./constants";
import CheckIcon from '@material-ui/icons/Check';

const static_files_base_url = base_site + "/static_files/subscriptions/";
const useStyles = ((theme) => ({
    chip: {
        margin: theme.spacing(0.5),
    },
    radioOption: {
        position: 'absolute',
        opacity: 0,
        width: 0,
        height: 0,

    },
    radioImg: {
        cursor: 'pointer',
        maxHeight: '2em',
        maxWidth: '2em',
        position: "absolute"
    },
    characterChecked: {
        outlineColor: '#76ff03'
    }

}));


class ChooseCharacter extends Component{
    constructor(props) {
        super(undefined);
        this.eyes = ["green", "brown", "blue"];
        this.data = {
            "boy": {
                "body": [
                    "boy_skin_1.png",
                    "boy_skin_2.png",
                    "boy_skin_3.png",
                    "boy_skin_4.png"
                ],
                "clothes": [
                    "boy_clothes_1.png",
                    "boy_clothes_2.png"
                ],
                "eyes": [
                    "boy_blue.png",
                    "boy_brown.png",
                    "boy_green.png"
                ],
                "hair": [
                    "boy_Hair_1_-_Black.png",
                    "boy_Hair_1_-_Blonde.png",
                    "boy_Hair_1_-_Brown.png",
                    // "boy_Hair_2_-_Black.png",
                    // "boy_Hair_2_-_Blonde.png",
                    // "boy_Hair_2_-_Brown.png",
                    // "boy_Hair_3_-_Black.png",
                    // "boy_Hair_3_-_Blonde.png",
                    // "boy_Hair_3_-_Brown.png"
                ],
                "headpiece": [
                    "boy_headpiece_0.png",
                    "boy_headpiece_1.png",
                    "boy_headpiece_2.png"
                ]
            },
            "girl": {
                "body": [
                    "girl_skin_1.png",
                    "girl_skin_2.png",
                    "girl_skin_3.png",
                    "girl_skin_4.png"
                ],
                "clothes": [
                    "girl_clothes_1.png",
                    "girl_clothes_2.png"
                ],
                "eyes": [
                    "girl_blue.png",
                    "girl_brown.png",
                    "girl_green.png"
                ],
                "hair": [
                    "girl_Hair_1_-_Black.png",
                    "girl_Hair_1_-_Blonde.png",
                    "girl_Hair_1_-_Brown.png",
                    // "girl_Hair_2_-_Black.png",
                    // "girl_Hair_2_-_Blonde.png",
                    // "girl_Hair_2_-_Brown.png",
                    // "girl_Hair_3_-_Black.png",
                    // "girl_Hair_3_-_Blonde.png",
                    // "girl_Hair_3_-_Brown.png"
                ],
                "headpiece": [
                    "girl_headpiece_0.png",
                    "girl_headpiece_1.png",
                    "girl_headpiece_2.png"
                ]
            }
        }
        this.gender = props.form_data.child.gender;
        const {character_options} = props.form_data;
        const {body, eyes, hair} = character_options
        this.state = {
            ...character_options,
            selected_option: 'eyes'
        }
        this.orientationToLeft = {
            left: [-10, -13], // span, h2
            right: [13, 13],
        }


    }

    h2Padded = (props) => {
        const [left_, h2left] = this.orientationToLeft[props.orientation];

        const orientation = this.orientationToLeft[props.orientation];

        console.log(orientation)
        return <div className={convertToSlug(props.text)}>
            {this.state.selected_option !== props.opt ?
                <span onClick={() => this.setState({selected_option: props.opt})} className={props.orientation}
                      style={{
                          top: props.top,
                          left: left_.toString() + 'em',

                      }}><span>&nbsp;</span></span>
                : <h2 className={props.orientation}
                      style={{
                          top: props.top,
                          width: `${props.text.length * 4.6}px`,
                          left: (h2left).toString() + 'em',
                      }}>{props.text}</h2>
            }
        </div>
    }

    componentDidMount() {
        this.props.handle_state_change({next_button_disabled: false})
        const state = this.state;
        // eslint-disable-next-line array-callback-return
        Object.entries(this.data[this.gender]).map(entry => {
            const [key, values] = entry;
            if (!state[key])
                state[key] = values[0];
        })
        this.setState(state);
    }
    handleChange = event => {
        const {name, value} = event.target
        let s = this.state;
        s[name] = value;
        this.setState(s);

        this.props.handle_change({
            target:{
                name: 'character_options',
                value: s,
            }
        });

    }
    render() {
        //const {value, } = this.state;
        const gender = this.gender;
        const {classes} = this.props;

        return (
            <Container>
                <form>
                    <Container className={'tail'} id='images'>
                        {Object.entries(this.state).map((entry) => {
                            const [name, value] = entry;
                            if (name !== 'selected_option')
                                return (
                                    <img src={static_files_base_url + gender + '/' + name + '/' + value}
                                         alt={name + ' ' + value}/>
                                );
                        })}
                        <this.h2Padded orientation={'left'} top={'2.3em'} text={'Hair'} opt={'hair'}/>
                        <this.h2Padded orientation={'right'} top={'3.8em'} text={'Eyes'} opt={'eyes'}/>
                        <this.h2Padded orientation={'left'} top={'8em'} text={'Body'} opt={'body'}/>
                    </Container>
                    <Container className={"optionContainer"}>

                        {Object.entries(this.data[gender]).map((types) => {
                            const [name, values] = types;
                            // console.log(types)
                            if (name == this.state.selected_option) {

                                return (<React.Fragment>
                                        <FormControl id={name} component="fieldset">
                                            <FormLabel component="div" className={'formLabel'}>{name}</FormLabel>
                                            <RadioGroup id={'cascade'} name={name} value={this.state[name]}
                                                        onChange={this.handleChange}
                                                        defaultValue={this.data[gender][name][0]}>
                                                {values.map(value => {
                                                        const parts = value.split('_')
                                                        const part = parts[parts.length - 1];
                                                        const color = part.split('.')[0];

                                                        return (

                                                            <Box
                                                                className={value == this.state[name] ? classes.characterChecked : ''}
                                                                id={'test_box'}>
                                                                <FormControlLabel value={value}
                                                                                  control={<Radio
                                                                                      className={classes.radioOption}/>}

                                                                                  label={
                                                                                      <span style={{width: '50px'}}>
                                                                  <div className={'thumbnail_box'}
                                                                       style={{display: "block"}}>
                                                                      {value == this.state[name] ?

                                                                          <CheckIcon style={{zIndex: 10}}
                                                                                     htmlColor={'green'}/> : ''}

                                                                      <img className={classes.radioImg}
                                                                           src={static_files_base_url + gender + '/' + name + '/thumbnail_' + value}//.replace('.png', '.jpg')}
                                                                           alt={name + ' ' + value}
                                                                           style={{zIndex: 5}}
                                                                      />
                                                                 </div>
                                                            </span>
                                                                                  }
                                                                >


                                                                </FormControlLabel>
                                                            </Box>
                                                        )
                                                    }
                                                )}
                                            </RadioGroup>
                                        </FormControl>
                                    </React.Fragment>

                                )
                            }
                        })}
                    </Container>

                </form>
            </Container>
        )
    }
}

export default withStyles(useStyles, {withTheme: true})(ChooseCharacter)
