import { SET_CURRENT_USER, GET_USER_ERROR, GET_USER_PROCESSING } from './actions-types';
import isEmpty from '../utils/isEmpty';
import store from "./store";
import * as auth from "./authActions";

let initialState;



initialState = {
    isAuthenticated: true,
    user: {},
};


export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
            };
        case GET_USER_ERROR:
            return {
                ...state,
                error: action.payload,
                isLogging: false,
                isAuthenticated: false,
            };

        case GET_USER_PROCESSING:
            return {
                ...state,
                isAuthenticated: true,
                processing: true,
            }
        default:
            return state;
    }
}
