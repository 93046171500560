import React, {Component, useState} from "react";
import {Container as DraggableContainer, Draggable} from "react-smooth-dnd";
import {arrayMoveImmutable as arrayMove} from "array-move";
import Themes from "./demo_data/themes";
import Books from "./demo_data/books";
import "./css/choose_book.css";
import {
    Grid,
    Checkbox,
    FormControl,
    Paper,
    Chip,
    FormGroup,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Collapse,
    Container, Box,
    Button, ListItemSecondaryAction
} from "@material-ui/core";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import StarBorder from '@material-ui/icons/StarBorder';
import {makeStyles} from "@material-ui/core";
import {withStyles} from "@material-ui/core";
import http from "../api-service/http-common";
import Notify from "../utils/Notify";
import {base_site} from "./constants";
import {Book} from "../api-service/drf_rest_base";


const UPLOAD_URL = "https://superpersonalisedbooks.co.uk/wp-content/uploads/";
const PRODUCT_URL = 'https://superpersonalisedbooks.co.uk/product/';

const useStyles = ((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

function DraggableSelectedList(props) {
    const [items, setItems] = useState([
        {id: "1", text: "Item 1"},
        {id: "2", text: "Item 2"},
        {id: "3", text: "Item 3"},
        {id: "4", text: "Item 4"}
    ]);

    const onDrop = ({removedIndex, addedIndex}) => {
        console.log([items, removedIndex, addedIndex]);

        let e = arrayMove(items, removedIndex, addedIndex)
        console.log(e);

        setItems(items => e);
    };

    return (
        <List>
            <DraggableContainer dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
                {items.map(({id, text}) => (
                    <Draggable key={id}>
                        <ListItem>
                            <ListItemText primary={text}/>
                            <ListItemSecondaryAction>
                                <ListItemIcon className="drag-handle">
                                    <DragHandleIcon/>
                                </ListItemIcon>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </Draggable>
                ))}
            </DraggableContainer>
        </List>
    );
}


class ChooseYourBook extends Component {
    constructor(props) {
        super(props);
        let {theme, books} = this.props.form_data;
        if (!books) {
            books = []
        }
        this.state = {
            themes: [],
            books: books,
            theme,
            books_selected: books,
        };
    }

    componentDidMount() {
        this.resetState();
        if (this.props.form_data.books.length === this.props.form_data.books_included) {
            this.props.handle_state_change({
                next_button_disabled: false,
            })
        }
    }
    form_validator = (checked,book) => {
        this.handle_book_change(checked, book);
        if(this.props.form_data.books.length===this.props.form_data.books_included){
            this.props.handle_state_change({
                next_button_disabled: false,
            })
        } else {

            this.props.handle_state_change({
                next_button_disabled: true,
            })
        }
    };
    is_disabled = (book) => {
        // console.log([this.props.form_data.disabledBooks,book])
        if (this.props.form_data.disabledBooks.includes(book.id)) {
            return true
        }
        if (this.props.anysize) {
            return false
        }
        if (this.state.books_selected.some((book_) => book_.id === book.id)) {
            return false
        }

        return (this.state.books_selected.length >= this.props.form_data.books_included)
        // (!this.state.books_selected.includes(book))
    }

    getThemes = () => {
        http.get(Book.getTheme).then(
            res => {
                this.setState({
                    themes: res.data.map((theme) => {
                        return {
                            id: theme.term__term_id,
                            name: theme.term__name,
                        }
                    }), theme: res.data[0].id
                });


                // console.log(this.state);
                }

        )
    };
    getBooks = (theme_id)=>{
        if(theme_id===this.state.theme){
            this.setState({theme: null });
            return;
        }
        this.setState({theme: theme_id, books: []});
        Notify.showLoading();

        http.get(Book.findBooks, {
            params: {
                theme_id: theme_id,
            }
        }).then(
            res => {
                let books = {
                    books: res.data.map((book) => {
                        return {
                            id: book.pk,
                            name: book.post_title,
                            image: book.image,
                            slug: book.slug,
                        }}),
                    };
                this.setState(books);
                const change = this.props.handle_change;
                change({
                        target: {
                            name: 'all_books',
                            value: books.books,
                        }
                    }
                );
                this.setState({});
                Notify.hideLoading()
            }

        )

    };
    resetState = ()=>{
      this.getThemes();
    };
    handle_book_change = (checked, book)=>{

        var value = book.id;

        var new_list;
        if(checked){
             new_list= this.state.books_selected.concat(book);

            this.setState({
                books_selected: new_list
            })
        }
        else{
            new_list = this.state.books_selected.filter((book_) =>{
                return book_.id !== book.id;
            });

            this.setState({
                books_selected: new_list
            })
        }
        this.props.handle_change({
            target: {
                name: 'theme',
                value: this.state.theme,
            }
            }
        );
        this.props.handle_change({
            target: {
                name: 'books',
                value: new_list,
            }
        });

    };

    selectRandomBooks = () => {
        Notify.showLoading();
        this.setState({books_selected: []});

        http.get(Book.randomBooks).then(res => {
            const books = res.data.map((book) => {
                return {
                    id: book.pk,
                    name: book.post_title,
                    image: book.image,
                    slug: book.slug,
                }
            });

            this.setState({books: books, books_selected: books});
            this.props.handle_change({
                target: {
                    name: 'books',
                    value: books,
                }
            });
            this.props.handle_state_change({
                next_button_disabled: false,
            })
            Notify.hideLoading();
        }).catch(err => {
            console.log(err);
            Notify.notifyError(err.response.message);
        })
    }

    clearBooks = () => {
        this.setState({books_selected: []});
        this.props.handle_state_change({
            next_button_disabled: true,
        })
    }

    render() {

        // console.log(JSON.stringify(books));
        // console.log(JSON.stringify(themes));
        console.log(this.state.books_selected);
        const {classes} = this.props;
        return (
            <Grid item xs={12} maxWidth={'lg'} class="container" style={{fontFamily: 'Poppins'}}>

                <Grid container spacing={2}>
                    <Grid sm={12} md={6} item>
                        {this.state.books_selected.length} out of {this.props.form_data.books_included} Books Selected
                    </Grid>
                    <Grid sm={12} item spacing={2} md={6}>
                        <Button onClick={this.selectRandomBooks} style={{marginRight: 3}}>Select Random Books</Button>
                        <Button onClick={this.clearBooks}>Clear Books</Button>
                    </Grid>
                </Grid>
                {Boolean(this.state.theme) ?
                    ''
                    :
                    <Grid container>
                        <Paper component="ul">
                            {this.state.books_selected.map((book) => {
                                let icon;
                                return (
                                    <li key={book.id}>
                                        <Chip
                                            icon={icon}
                                            label={book.name}
                                            onDelete={() => this.form_validator(false, book)}
                                        />
                                    </li>
                                );
                            })}
                        </Paper>
                    </Grid>

                }

                <List>
                    {
                        !this.state.themes || this.state.themes.length <= 0 ? ('') : (this.state.themes.map((theme, index) => (
                            <>
                                <ListItem button onClick={() => this.getBooks(theme.id)}>

                                    <ListItemText>{theme.name} </ListItemText>
                                    {this.state.theme === theme.id ? <ExpandLess/> : <ExpandMore/>}
                                </ListItem>

                                <Collapse in={this.state.theme === theme.id} timeout="auto" unmountOnExit>
                                    <Grid container spacing={5} alignItems="flex-start">
                                        {

                                            !this.state.books || this.state.books.length <= 0 ? ('') : this.state.books.map(book => (


                                                <Grid item xs={6} sm={4} md={2} className={'book'}>
                                                    <Grid container>
                                                        <label>
                                                            <Grid container>
                                                                <img id={'book_img'} src={UPLOAD_URL + book.image}
                                                                     style={{width: '100%',}}/>
                                                            </Grid>

                                                            <Grid container>
                                                                <Checkbox
                                                                    name="books"
                                                                    value={book.id}
                                                                    checked={!!this.state.books_selected.find(b => b.id === book.id)}
                                                                    onClick={(event) => this.form_validator(event.target.checked, book)}
                                                                    disabled={(this.is_disabled(book))}
                                                        />{book.name}
                                                    </Grid>
                                                    </label>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Button component={'a'} target='_blank'
                                                                    variant={'contained'}
                                                                    href={PRODUCT_URL + book.slug}>View Now</Button>
                                                        </Grid>
                                                    </Grid>
                                                    </Grid>

                                                </Grid>
                                            ))
                                        }
                                    </Grid>

                                    <Grid container >
                                        <Paper component="ul">
                                            {this.state.books_selected.map((book) => {
                                                let icon;
                                                return (
                                                    <li key={book.id}>
                                                        <Chip
                                                            icon={<img src={UPLOAD_URL + book.image} alt={book.name}/>}
                                                            label={book.name}
                                                            onDelete={() => this.form_validator(false, book)}
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </Paper>
                                    </Grid>


                                </Collapse>


                            </>

                        )))
                    }

                </List>
                {Boolean(this.state.theme) ?
                    ''
                    :
                    <Grid container>
                        <Paper component="ul">
                            {this.state.books_selected.map((book) => {
                                let icon;
                                return (
                                    <li key={book.id}>
                                        <Chip
                                            icon={icon}
                                            label={book.name}
                                            onDelete={() => this.form_validator(false, book)}
                                        />
                                    </li>
                                );
                            })}
                        </Paper>
                    </Grid>

                }
            </Grid>
        )
    }
}

export default withStyles(useStyles, {withTheme: true}) (ChooseYourBook);

