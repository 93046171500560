import http from "./http-common";


class CustomerDetails{
    static endpoint;

    is_logged_in(){
        return http.get('/subscriptions/user-detail/', {withCredentials: true});
    }

}

export default new CustomerDetails();
