/*
 * @abstract
 */

import http from "./http-common";
import Notify from "../utils/Notify";

export default class DrfRestBase {
    static endpoint;

    static get(config) {
        return http.get(this.endpoint, config);
    }

    static post(data) {
        console.log(data);
        if (data.id) {
            return this.update(data)
        }

        return http.post(this.endpoint, data);
    }

    static update(data) {
        return http.patch(this.endpoint + data.id + '/', data);
    }

    static retrieve({id}) {
        return http.get(this.endpoint + id + '/');
    }

    static delete(data) {
        return http.delete(this.endpoint + data.id + '/');
    }
}

export class SubscriptionStatus {
    static paused = 'paused';
    static active = 'active';
    static cancelled = 'cancelled'
}

export class Plan extends DrfRestBase {
    static endpoint = '/subscriptions/api/plans/';

}

export class Child extends DrfRestBase {
    static endpoint = '/subscriptions/api/child/';
}

export class Address extends DrfRestBase {
    static endpoint = '/subscriptions/api/address/';
}

export class Subscription extends DrfRestBase {
    static  endpoint = '/subscriptions/api/subscriptions/';

    static start_monthly(payload) {
        return http.post(this.endpoint + 'start_monthly/', payload)
    }

    static pause(subscription_id) {
        return Subscription.update({id: subscription_id, status: SubscriptionStatus.paused})
    }

    static resume(subscription_id) {
        return Subscription.update({id: subscription_id, status: SubscriptionStatus.active})

    }

    static cancel(subscription_id) {
        return Subscription.update({id: subscription_id, status: SubscriptionStatus.cancelled})
    }
}

export class Book extends DrfRestBase {
    static endpoint = '/subscriptions/api/order-books/';
    static findBooks = '/api/books/';
    static randomBooks = '/api/random-books/';
    static getTheme = '/api/themes/'
}
