import React, {Component} from "react";
import {Grid} from "@material-ui/core";
import './css/choos_your_plan.css';
import Plans from "./demo_data/plans";
import DataTable from "react-data-table-component";
const columns = (update_handler,original_price, form_data) => ([
    {
        name: 'What Do you Get',
        selector: 'name',
        sortable: true,
        cell: plan => <Grid container><Grid xs="12">{plan.name}</Grid><span>{plan.books_inc} Books</span></Grid>
    },
    {
        name: 'One UpFront Payment',
        selector: 'one_payment',
        sortable: true,
        cell: (plan) => (<Grid container><label> <input  checked={form_data.payment_type==plan.id && form_data.is_single} type="radio" name="payment_type" value={plan.id.toString()} onChange={(event) => update_handler(event,plan,true)}/>
             {plan.one_payment}</label>
                   </Grid>)
        // omit: hideDirector,
    },
    {
        name: 'Monthly Payment',
        selector: 'year',
        sortable: true,
        cell: (plan => (<Grid container>
            {(plan.recurring!==0) ?
                <><label xs={12}> <input checked={form_data.payment_type==plan.id && ~form_data.is_single} type="radio" name="payment_type"  value={plan.id.toString()} onChange={(event) => {update_handler(event,plan,false)}}/> {plan.recurring}</label></>:<span>Not Available</span>}

        </Grid>))
    },
    {
        name: 'Price Per book',
        sortable: true,
        cell: (plan => (<>{plan.one_payment/plan.books_inc}</>))
    }   ,
    {
        name: 'Your Savings',
        sortable: false,
        cell: (plan => (<>{(original_price * plan.books_inc - plan.one_payment).toFixed(1)}</>))
    }
])

class PlansInfo extends Component {
    update_handler = (e,plan, is_single ) => {
        const update_handler = this.props.update_handler;

        // let plan = this.props.plan;
        update_handler ( {
            target: {
                name: 'is_single',
                value: is_single,
            }
        })
        update_handler(e);
        let price = {
            target: {
                name: 'price',
                value: plan.one_payment,
            }
        };
        update_handler(price);
        let book_inc = {
            target: {
                name: 'books_included',
                value: plan.books_inc,
            }
        };
        update_handler(book_inc);
        let plan_name = {
            target: {
                name: 'plan_name',
                value: plan.name,
            }
        };
        update_handler(plan_name)

    };


    render(x) {
        const plan_type = this.props.plan_type;
        const plans = Plans.get_plans(plan_type);

        // console.log(plans);
        return (
            <div id={'data_table'}>
                <DataTable
                    title="SELECT ONE OPTION"
                    columns={columns(this.update_handler, plans.original_price, this.props.form_data)}
                    data={plans.values}
                    update_handler={this.update_handler}
                />

            </div>


    );

        // return super.render();
    }


}

class SinglePlan extends Component{

    constructor(props) {
        super(props);

    }
    update_handler = (e,is_single) => {
        const update_handler = this.props.update_handler;
        let plan = this.props.plan;

        update_handler(e);
        let price = {
            target: {
                name: 'price',
                value: plan.one_payment,
            }
        };
        update_handler(price);
        let book_inc = {
            target: {
                name: 'books_included',
                value: plan.books_inc,
            }
        };
        update_handler(book_inc);
        let plan_name = {
            target: {
                name: 'plan_name',
                value: plan.name,
            }
        };
        update_handler(plan_name)
        update_handler ( {
            target: {
                name: 'is_single',
                value: is_single,
            }
        })
    };
    render() {
        let plan = this.props.plan;
        return (

            <Grid container>
                <Grid item xs={2} s={2}>{plan.name}<br/><span>{plan.books_inc} Books</span></Grid>
                <Grid item xs={2} s={2}>{plan.one_payment}
                    <input type="radio" name="payment_type" value={plan.id.toString(10)} onChange={(event) => {this.update_handler(event,true)}}/>
                    <br/>
                </Grid>
                <Grid item xs={2} s={2}>{plan.recurring}
                    <br/>{(plan.recurring!==0) ? <input type="radio" name="payment_type"  value={plan.id.toString(10)} onChange={(event) => {this.update_handler(event,false)}}/>:<span></span>}
                </Grid>
                <Grid item xs={2} s={2}>{plan.one_payment/plan.books_inc}</Grid>
                <Grid item xs={2} s={2}>{(this.props.original_price * plan.books_inc - plan.one_payment).toFixed(1)}</Grid>
            </Grid>

        )
    }
}

export default PlansInfo;
