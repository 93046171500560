import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {FormControlLabel, Radio} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import LearnMore from "./helpers/learn-more";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),

    },
    detail_row:{
        marginBottom: theme.spacing(2),

    },
    detail_row_cell: {
        lineHeight: '2.5em',
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    cardSelected: {
        border: "1em",
        outlineStyle: 'solid',
        outlineColor: green.A400,
        zIndex: -100,
    },
    card: {
        "&:hover": {
            background: "#efefef"
        }
    }
}));

const tiers = [
    {
        title: 'Free',
        price: '0',
        description: ['10 users included', '2 GB of storage', 'Help center access', 'Email support'],
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined',
    },
    {
        title: 'Pro',
        subheader: 'Most popular',
        price: '15',
        description: [
            '20 users included',
            '10 GB of storage',
            'Help center access',
            'Priority email support',
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
    },
    {
        title: 'Enterprise',
        price: '30',
        description: [
            '50 users included',
            '30 GB of storage',
            'Help center access',
            'Phone & email support',
        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
    },
];
const footers = [
    {
        title: 'Company',
        description: ['Team', 'History', 'Contact us', 'Locations'],
    },
    {
        title: 'Features',
        description: ['Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one'],
    },
    {
        title: 'Resources',
        description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
    },
    {
        title: 'Legal',
        description: ['Privacy policy', 'Terms of use'],
    },
];

export default function Pricing() {
    const classes = useStyles();

    return (
        <React.Fragment>
            {/* Hero unit */}
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Pricing
                </Typography>
                <Typography variant="h5" align="center" color="textSecondary" component="p">
                    Quickly build an effective pricing table for your potential customers with this layout.
                    It&apos;s built with default Material-UI components with little customization.
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    {tiers.map((tier) => (
                        // Enterprise card is full width at sm breakpoint
                        <PricingCard tier={tier}/>
                    ))}
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export function PricingCard(props) {
    let {tier} = props;
    const classes = useStyles();
    const {is_monthly, set_monthly} = useState(true);
    tier = tier[0]
    const hardback = tier;
    const paperback_price = tier.price;
    const hardback_price = tier.hardbackPrice;

    return <Grid item key={tier.name} xs={12} sm={tier.name === 'Monthly' ? 12 : 6} md={3}>
        <Card
            className={props.value === tier.id ? classes.cardSelected : classes.card}
            onClick={() => {
                props.onChange({target: {name: props.name, value: tier}})
            }}>
            <CardHeader
                title={tier.name}
                subheader={<React.Fragment>
                    {tier.short_description}
                    <LearnMore>{tier.long_description}</LearnMore>
                </React.Fragment>}
                titleTypographyProps={{align: 'center'}}
                subheaderTypographyProps={{align: 'center'}}
                action={tier.title === 'Pro' ? <StarIcon/> : null}
                style={{backgroundColor: tier.color}}
            />
            <CardContent style={{textAlign: 'center'}}>
                <Grid item xs={12}>
                    <div className={classes.cardPricing}>
                        <Typography component="h2" variant="h5" color="textPrimary">
                            £ {(tier.price / tier.processing_frequency * 30).toFixed(2)} <br/> Monthly
                        </Typography>
                </div>


                <Grid className={classes.detail_row} container>
                    {hardback.is_monthly ?
                        <Grid item xs={4}>Monthly<br/> Payment<br/></Grid>
                        :
                        <Grid item xs={4}>UpFront<br/> Payment</Grid>
                    }
                    <Grid item className={classes.detail_row_cell} id={tier.type} xs={4}>Paperback<br/>
                    </Grid>
                    <Grid item className={classes.detail_row_cell} id={hardback.type}
                          xs={4}>Hardback<br/> </Grid>
                    <Grid item xs={4}/>

                    <Grid item className={classes.detail_row_cell} id={tier.type} xs={4}> <FormControlLabel
                        style={{marginLeft: '0.5em'}} value={'paperback'} checked={props.book_type === 'paperback'}
                        name={'book_type'} onChange={props.bookTypeUpdated} control={<Radio/>} label=""/></Grid>
                    <Grid item className={classes.detail_row_cell} id={tier.type} xs={4}> <FormControlLabel
                        style={{marginLeft: '0.5em'}} value={'hardback'} checked={props.book_type === 'hardback'}
                        name={'book_type'} onChange={props.bookTypeUpdated} control={<Radio/>} label=""/></Grid>

                </Grid>



                <Grid className={classes.detail_row} container>
                    <Grid item xs={4}>Price <br/>Per<br/> Book</Grid>
                    <Grid item className={classes.detail_row_cell} id={tier.type} xs={4}>£{paperback_price}</Grid>
                    <Grid item className={classes.detail_row_cell} id={hardback.type} xs={4}>£{hardback_price}</Grid>
                </Grid>
                    <Grid className={classes.detail_row} container>
                        {hardback.is_monthly ?
                            <Grid item xs={4}>Saving</Grid>
                            :
                            <Grid item xs={4}>Saving</Grid>
                        }

                        <Grid item className={classes.detail_row_cell} id={tier.type} xs={4}>£ {tier.saving}</Grid>
                        <Grid item className={classes.detail_row_cell} id={hardback.type}
                              xs={4}>£ {tier.hardbackSaving}</Grid> </Grid>

                </Grid>
                <Grid className={classes.detail_row} container>

                        <Grid item xs={4}>% Savings</Grid>


                    <Grid item className={classes.detail_row_cell} id={tier.type}
                          xs={4}>{(tier.saving / tier.price).toFixed(2) * 100} %</Grid>
                    <Grid item className={classes.detail_row_cell} id={hardback.type}
                          xs={4}>{(tier.hardbackSaving / tier.hardbackPrice).toFixed(2) * 100} % </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button fullWidth variant={tier.buttonVariant} color="primary">
                    {tier.buttonText}
                </Button>
            </CardActions>
        </Card>
    </Grid>;
}
