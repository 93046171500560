import React, {Component} from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Table,
    TableBody, TableCell,
    TableContainer, TableRow,
    FormControlLabel, Checkbox, TableHead, ButtonGroup
} from "@material-ui/core";
import {base_site, site_urls} from "./constants";
import http from "../api-service/http-common";
import {withStyles} from "@material-ui/core/styles";
import BookList from "./book/list";
import api from "../api-service/api";
import Notify from "../utils/Notify";
import CustomizedDialogs from "./modal-template";
import ChooseBooks from "./choose-books";

const url = base_site + '/subscriptions/api/subscriptions/';


class SubscriptionDetail extends Component {
    constructor(props) {
        super(undefined);
        this.pk = parseInt(props.match.params.id, 10);

        this.form_data = {
            books_included: 0, books: [], disabledBooks: [],
        }
        this.state = {
            subscription: null
        }
    }

    pauseSubscription = (event) => {
        Notify.showLoading();
        api.Subscription.pause(this.pk).then(res => {
            Notify.notifySuccess('Paused')
        }).catch(response => {
            Notify.notifyError('Error Occurred');
        });
    }
    resumeSubscription = (event) => {
        Notify.showLoading();
        api.Subscription.resume(this.pk).then(res => {
            Notify.notifySuccess('Resumed')
        }).catch(response => {
            Notify.notifyError('Error Occurred');
        });
    }

    cancelSubscription = (event) => {
        Notify.showLoading();
        api.Subscription.cancel(this.pk).then(res => {
            Notify.notifySuccess('Canceled')
        }).catch(response => {
            Notify.notifyError('Error Occurred');
        });
    }

    componentDidMount() {
        console.log(this.props.match)
        this.pk = parseInt(this.props.match.params.id, 10);
        http.get(url + this.pk.toString() + '/').then(res => {
            const subscription = res.data;
            this.setState({subscription})
            // this.form_data.disabledBooks = subscription.books;
            subscription.books.map((book, index) => {
                this.form_data.disabledBooks.push(book.book)
            })
        });

    }

    handle_form_change = event => {
        const {name, value} = event.target;

        this.form_data[name] = value;
        // localStorage.setItem('formData', JSON.stringify(this.form_data))
        console.log(this.form_data);

    };
    addBooks = event => {
        Notify.notifyInfo('Adding Books', 'loading')
        const previous_length = this.state.subscription.books.length;
        Promise.all(this.form_data.books.map(async (book, index) => {
            // const {giftbox, message, message_text, image} = this.form_data.book_props[book.id]

            let book_data = {book: book.id, subscription: this.pk, order: index + previous_length}
            // book_data.book_props = JSON.stringify({giftbox, message, message_text, image})
            book_data.book_props = "{}";
            console.log(book_data)
            return await api.Book.post(book_data)
        }))
        Notify.notifySuccess('Added', true);

    }
    render() {
        const {subscription} = this.state;
        if (subscription) {

            const {status} = subscription;
            const {classes} = this.props;
            console.log(subscription);
            return (
                <Container className={classes.subscription_container}>
                    {subscription ?
                        <React.Fragment>

                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell class={classes.headerCell}>Plan</TableCell>
                                            <TableCell>{subscription.plan_detail.name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell class={classes.headerCell}>Status</TableCell>
                                            <TableCell>{subscription.status}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell class={classes.headerCell}>Subscribed at</TableCell>
                                            <TableCell>{subscription.created_at}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell class={classes.headerCell}>Total Paid Yet</TableCell>
                                            <TableCell>{subscription.total_paid}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <CustomizedDialogs title={'Add Books'} fullScreen={true}>
                                                    <ChooseBooks
                                                        form_data={this.form_data}
                                                        handle_state_change={() => {
                                                        }}
                                                        handle_change={this.handle_form_change}
                                                        anysize={true}

                                                    />
                                                    <Button onClick={this.addBooks}> Add</Button>
                                                </CustomizedDialogs>
                                            </TableCell>
                                        </TableRow> <TableRow>
                                        <TableCell colSpan={2}>
                                            <BookList subscription={subscription}/>
                                        </TableCell>
                                    </TableRow>

                                        <TableRow>
                                            {status === api.SubscriptionStatus.cancelled ?
                                                <React.Fragment></React.Fragment>
                                                :
                                                <ButtonGroup variant="contained"
                                                             aria-label="outlined primary button group">
                                                    {status !== api.SubscriptionStatus.paused ?
                                                        <Button onClick={this.pauseSubscription}
                                                                color="secondary">Pause</Button> : ''}

                                                    {status !== api.SubscriptionStatus.active ?
                                                        <Button onClick={this.resumeSubscription}
                                                                color="success">Resume</Button> : ''}
                                                    <Button onClick={this.cancelSubscription}
                                                            color="error">Cancel</Button>

                                                </ButtonGroup>
                                            }

                                        </TableRow>

                                    </TableBody>


                                </Table>

                            </TableContainer>


                        </React.Fragment>

                        : ''
                    }
                </Container>
            );
        } else {
            return <React.Fragment></React.Fragment>
        }
    }
}

const styles = (theme => ({
    root: {
        minWidth: 275,
        marginTop: theme.spacing(5),
    },
    subscription_container: {
        marginTop: '1em',
        marginBottom: '2em',
    },
    title: {
        fontSize: 14,
    },
    headerCell: {
        fontWeight: 700,
    },
    table: {
        marginTop: theme.spacing(4),
    }
}));

export default withStyles(styles, {withTheme: true})(SubscriptionDetail);

