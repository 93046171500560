import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React from "react";
import ChooseYourBook from "./components/choose-books";
import WooCommerceAPI from "woocommerce-api";

const api = new WooCommerceAPI({
    url: 'https://superpersonalisedbooks.co.uk',
    consumerKey: 'ck_c3212644c6e206dbfd06c0eafc712e52930ada81',
    consumerSecret: 'cs_6c7215f726c0f954e443957d00ab5c955113d170',
    wpAPI: true,
    version: 'wc/v3'
})
function getStepContent(step) {
    switch (step) {
        case 0:
            return <ChooseYourBook handle_state_change={''} handle_change={''} form_data={''}/>
                ;
        case 1:
            return 'An ad group contains one or more ads which target a shared set of keywords.';
        case 2:
            return `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`;
        default:
            return 'Unknown step';
    }
}

export default function VerticalLinearStepper() {
    const classes = {};
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getStepContent();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    api.getAsync('products').then((results) => console.log((results.toJSON().body)))
    return (
        <React.Fragment>
            Hellow
        </React.Fragment>
    );
}
