import {base_site, site_urls} from "./constants";
import React from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent, CardHeader,
    Container,
    Grid, Link,
    withStyles
} from "@material-ui/core";
import http from "../api-service/http-common";
import Notify from "../utils/Notify";

const url = base_site + '/subscriptions/api/subscriptions/';
const portal_url = base_site + '/payments/customer-portal';

class ListSubscriptions extends React.Component{

    constructor(props) {
        super(undefined);
        this.state = {
            subscriptions: []
        }

    }

    componentDidMount() {
        this.getSubscriptions();
    }

    getSubscriptions() {
        http.get(url).then((res) => {
            console.log(res.data);
            this.setState({subscriptions: res.data.results});
        })
    }

    get_portal = () => {
        Notify.showLoading();
        http.get(portal_url).then(res => {
            window.open(res.data.url);
        })
    }

    render() {
        const classes = this.props.classes;
        return (
            <Container className={classes.root}>
                <Grid container className={classes.actionContainer} spacing={2}>
                    <Grid item>
                        <Button component={'a'} href={site_urls.start_process.url.replace(':planSlug', 'newborn')}
                                variant={'contained'}
                                color={'primary'}>Start
                            New Subscription</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={this.get_portal} variant={'contained'} color={'primary'}>
                            Get Customer Portal
                        </Button>

                    </Grid>
                </Grid>
                <Container spacing={2} maxWidth="lg">
                    <Grid container spacing={5} alignItems={'flex-end'}>

                        {this.state.subscriptions.map((subscription) => (
                                <Grid item xs={12} md={4} spacing={2}>
                                    <Card className={classes.Card}>
                                        <CardContent>
                            <Grid container className={classes.alignCenter}>
                                <Grid item xs={4} alignItems={"space-around"} justify={"space-around"}>
                                    <h2>{subscription.child_name}</h2></Grid>

                            </Grid>
                            <hr/>
                            <Grid container>
                                <Grid item className={classes.cardHeader} xs={6}>
                                    Plan Name
                                </Grid>
                                <Grid item xs={6}><br/>
                                    {subscription.plan_detail.name}
                                </Grid>
                            </Grid>
                            <Grid container>

                            <Grid item className={classes.cardHeader} xs={6}>
                                Subcription date
                            </Grid>
                            <Grid item xs={6}><br/>
                                {subscription.created_at}
                            </Grid>
                        </Grid>
                        <Grid container>

                            <Grid item className={classes.cardHeader} xs={6}>
                                Amount
                            </Grid>
                            <Grid item xs={6}><br/>
                                {subscription.price}
                            </Grid>
                        </Grid>
                        <Grid container>

                            <Grid item className={classes.cardHeader} xs={6}>
                                Books Inc
                            </Grid>
                            <Grid item xs={6}><br/>
                                {subscription.plan_detail.books_included}
                            </Grid>
                        </Grid>

                        </CardContent>
                        <CardActions className={classes.alignCenter}>
                            <Button component={'a'}
                                    href={site_urls.detail_subscription.url.replace(':id', subscription.pk)}
                                    size='small' variant={'contained'} color={'secondary'}>See Details</Button>
                        </CardActions>
                    </Card>
                </Grid>
                    )
                )}
                </Grid>

            </Container>

            </Container>
        )
    }

}

const styles = ( theme => ({
    root: {
        minWidth: 275,
        marginTop: theme.spacing(5),
    },
    actionContainer: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cardHeader: {
        fontSize: 16,
        fontWeight: 800,
    },
    Card: {
        backgroundColor: 'silver',
    },
    alignCenter: {
        justifyContent: 'space-around',
    },

}));
export default withStyles(styles, {withTheme: true}) (ListSubscriptions);
