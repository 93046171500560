import {React, Component} from "react";
import {Button, Input, FormLabel, InputLabel, Grid, FormGroup, FormControl, Box, withStyles} from "@material-ui/core";
import {green} from "@material-ui/core/colors";

const useStyles = ((theme) => ({
    genderButton: {
        margin: theme.spacing(0.5),
        color: green.A400,
    },
}));

class NameCharacter extends Component {
    constructor(props) {

        super(props);
        const {child} = this.props.form_data;
        this.state = child

    }

    gender_clicked = (value) => {
        // let target = event.target;
        // let value = target.attributes['data-val'].value;
        // $('#gender_input').val(value);
        // this.setState({
        //     gender: value,
        // });
        this.form_validator({
            target: {
                name: 'gender',
                value: value,
            }
        })

    };
    gender;
    child_name;
    componentDidMount() {
        if (this.props.form_data.child.child_name && this.props.form_data.child.gender) {
            this.props.handle_state_change({
                next_button_disabled: false,
            })
        } else {
            this.props.handle_state_change({
                next_button_disabled: true,
            })
        }
    }

    form_validator = event => {
        let state = this.state;
        state[event.target.name] = event.target.value;
        if (state.child_name && state.gender !== undefined) {
            this.props.handle_change({
                target: {
                    name: 'child',
                    value: state,
                }
            });
            this.props.handle_change({
                target: {
                    name: 'character_options',
                    value: {},
                }
            });
            this.props.handle_state_change({
                next_button_disabled: false,
            });
            // this.setState({[event.target.name]: event.target.value});

        } else {
            this.props.handle_state_change({
                next_button_disabled: true,
            })
        }
        this.setState(state);

    };


    render() {
        const {classes} = this.props;
        return (
            <Box display={'flex'} justifyContent="center">
            <Grid item>
                <h1>
                    Create your Personalised Character
                </h1>
                <Grid container>
                <FormControl>
                    <InputLabel>Child's Name</InputLabel>
                    <Input type={"text"} name="child_name" value={this.state.child_name}
                           onChange={this.form_validator}/>

                </FormControl>
                </Grid >

                    <Grid container style={{marginTop: '2em'}}>
                        <Grid container><InputLabel>Gender</InputLabel></Grid>

                        <FormControl style={{marginTop: '1em'}}>
                            <Grid container id={'gender_choices'}>

                                <Grid item xs={6}><Button variant="outlined" color="secondary"
                                                          id={this.state.gender === 'boy' ? '' : 'notSelected'}
                                                          data-val='boy' onClick={() => this.gender_clicked('boy')}
                                                          style={{marginRight: '1em'}}>Boy</Button></Grid>
                                <Grid item xs={6}><Button variant="outlined" color="secondary"
                                                          id={this.state.gender === 'girl' ? '' : 'notSelected'}
                                                          data-val='girl'
                                                          onClick={() => this.gender_clicked('girl')}>Girl</Button></Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
            </Grid>

            </Box>
        );
    }
}


export default withStyles(useStyles, {withTheme: true})(NameCharacter)
