import React, {Component} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Button, Checkbox, Container, FormLabel, Grid, Input, LinearProgress, Switch} from "@material-ui/core";
import Cropper from "react-cropper";
import http from "../api-service/http-common";
import {PartialPrice} from "./total-price";
import {base_site} from "./constants";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
}));
export default class ImageGiftBox extends Component {
    constructor(props) {
        super(props);
        const book = this.props.form_data.book_props[props.book.id];
        this.state = {
            ...book,
            preview: true,
            uploading: false,
            progress: 0,
            messsage: false,

        }
        if (!this.state.giftbox)
            this.state.giftbox = false;
        this.cropper = null;
        this.state.book_id = props.book.id;
    }
    handle_message= (e) => {
        // console.log({message: e.target.checked});
        let state = this.state;
        const messageEnabled = e.target.checked;
        state.message = messageEnabled;
        this.setState({message: messageEnabled});
        console.log(state)
        this.props.update_form({
            target: {
                name: this.state.book_id,
                value: state,
            }
        })
        if (messageEnabled) {
            this.props.handle_state_change(this.state.book_id, true,)
        } else {
            this.props.handle_state_change(this.state.book_id, false,)
        }


    };

    handle_gift_box = (e) => {
        let state = this.state;
        state.giftbox = e.target.checked;
        this.setState({giftbox: e.target.checked});
        this.props.update_form({
            target: {
                name: this.state.book_id,
                value: state,
            }
        })

    };
    process_img = (e) => {
        e.preventDefault();
        let files;

        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            let state = this.state;
            state.image = reader.result;
            state.preview = false;

            this.setState(state);
            this.props.update_form({
                target: {
                    name: this.state.book_id,
                    value: state,
                }
            })
        };
        reader.readAsDataURL(files[0]);
    }
    confirm_clicked = event => {

        let state = this.state;
        state.image = this.cropper.getCroppedCanvas().toDataURL();
        state.preview = true;
        state.uploading = true;
        this.setState(state);
        this.props.handle_state_change(this.state.book_id, true);

        http.post('set_picture/', {picture: state.image}, {
                headers: {},
                onUploadProgress: this.onUploadProgress
            }
        ).then(res => {
                state.image = base_site + res.data
                this.props.update_form({
                    target: {
                        name: this.state.book_id,
                        value: state,
                    }
                })
                this.setState({uploading: false, preview: true})
                if (this.state.message_text)
                    this.props.handle_state_change(this.state.book_id, false);

            }
        )

    }
    onUploadProgress = (event) => {
        this.setState({
            progress: Math.round((100 * event.loaded) / event.total),
        });
    }

    render() {
        const {image} = this.state;
        let book = this.props.book;
        window.state = (this.state);
        return (

            <Container maxWidth={"xl"}>
                <Grid item xs={12}>
                    <FormLabel>

                            <Checkbox checked={this.state.giftbox}  name={"giftbox"} onClick={this.handle_gift_box}/>
                            &nbsp;GiftBox
                        </FormLabel>
                        <br/>
                        <FormLabel>
                            <Checkbox  checked={this.state.message} name={"message-"+book.id.toString()} onClick={this.handle_message}/>
                            &nbsp;Upload image and add Message
                        </FormLabel>
                        {this.state.message ? (
                            <div>
                                <br/>
                                <Input multiline type={'textarea'} fullWidth required value={this.state.message_text}
                                       onChange={(e) => {
                                           let state = this.state
                                           state.message_text = e.target.value;
                                           this.setState({message_text: e.target.value})
                                           this.props.update_form({
                                               target: {
                                                   name: this.state.book_id,
                                                   value: state,
                                               }
                                           })
                                           if (this.state.image && ~this.state.uploading)
                                               this.props.handle_state_change(this.state.book_id, false);

                                       }
                                       }

                                       placeholder={'Enter Message'}/>
                                <br/>
                                <Button
                                    variant="contained"
                                    component="label"
                                    style={{margin: '1em'}}
                                >
                                    Upload Image
                                    <input
                                        type="file"
                                        accept={'image/png'}
                                        hidden
                                        onChange={this.process_img}

                                    />
                                </Button>
                                {this.state.preview ?
                                    <Container>
                                        <img id={'book_img'} src={image}
                                             style={{width: '100%', height: 'auto', maxWidth: '20em'}}/>
                                    </Container>
                                    :
                                    <>
                                        <Container id={'cropper'} style={{marginBottom: '1em'}}>
                                            <Cropper
                                                style={{ height: 400, width: "100%" }}
                                                zoomTo={0.5}
                                                aspectRatio={12/8}
                                                preview=".img-preview"
                                                src={image}
                                                viewMode={1}
                                                guides={true}
                                                minCropBoxHeight={10}
                                                minCropBoxWidth={10}
                                                background={false}
                                                responsive={true}
                                                autoCropArea={0.7}
                                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                onInitialized={instance => this.cropper = instance}
                                            />
                                        </Container>
                                        <Container>
                                            {this.state.uploading ?
                                                <LinearProgress variant="determinate" value={this.state.progress}/>
                                                :
                                                <div></div>
                                            }
                                            <Grid item>
                                                <Button color='primary' variant='contained'
                                                        onClick={this.confirm_clicked}>Confirm</Button>
                                            </Grid>
                                        </Container>

                                    </>
                                }



                            </div>
                        ):("")}
                    <PartialPrice giftbox_state={this.state} form_data={this.props.form_data}/>
                    </Grid>
                </Container>
        )
    }
}

