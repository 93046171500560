import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Container} from "@material-ui/core";
import {removeItemOnce} from "./constants";
const required_fields = ['firstName', 'lastName', 'city', 'address1', 'state', 'zip', 'country']

class ShippingAddressForm extends React.Component {
    constructor(props) {
        super(undefined);

        this.state = props.form_data.address;
        // add address: {} in form_data
    }

    componentDidMount() {
        required_fields.forEach(field => {
            if (~this.state[field]) {
                {
                    this.props.handle_state_change({
                        next_button_disabled: true,
                    })
                }
                return; // requiredfields not matched
            }
        });
        this.props.handle_state_change({
            next_button_disabled: false,
        })
    }

    onChange = (event) => {
        let state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state);

        required_fields.forEach(field => {
            if (~state[field]) {
                {
                    this.props.handle_state_change({
                        next_button_disabled: true,
                    })
                }
                return; // requiredfields not matched
            }
        });
        // Required fields Filled
        this.props.handle_change({
            target: {
                name: 'address',
                value: state,
            }
        })
        this.props.handle_state_change({
            next_button_disabled: false,
        })
    }
    dayPrefrenceChanged = (day_value) => (event) => {
        console.log(day_value);
        if (event.target.checked) {
            this.props.form_data.preferred_days.push(day_value);
        } else {
            removeItemOnce(this.props.form_data.preferred_days, day_value);
        }
        this.setState({});
    }

    render() {
        const {preferred_days, plan_name} = this.props.form_data;
        return (

            <Container>
                {true | (plan_name == 'Fortnightly') ? '' :
                    <>
                        <Typography variant="h6">
                            Process the order on
                        </Typography>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={preferred_days.includes(1)}
                                                       onChange={this.dayPrefrenceChanged(1)} name="15"/>}
                                    label="Process On 1st"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={preferred_days.includes(15)}
                                                       onChange={this.dayPrefrenceChanged(15)} name="15"/>}
                                    label="Process On 15th"
                                />
                            </Grid>
                        </Grid>
                    </>}
                <Typography variant="h6" gutterBottom>
                    Shipping address
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="firstName"
                            name="firstName"
                            label="First name"
            value={this.state.firstName}
            onChange={this.onChange}
            fullWidth
            autoComplete="given-name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            value={this.state.lastName}
            onChange={this.onChange}

/>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line 1"
            value={this.state.address1}
            onChange={this.onChange}
            fullWidth
            autoComplete="shipping address-line1"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Address line 2"
            value={this.state.address2}
            onChange={this.onChange}
            fullWidth
            autoComplete="shipping address-line2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
            value={this.state.city}
            onChange={this.onChange}

          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField id="state" name="state" label="State/Province/Region" fullWidth
            value={this.state.state}
            onChange={this.onChange}
 />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            value={this.state.zip}
            onChange={this.onChange}

          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
              required
              id="country"
              name="country"
              label="Country"
              fullWidth
              autoComplete="shipping country"
              value={this.state.country}
              onChange={this.onChange}

          />
        </Grid>

          </Grid>
      </Container>
  );
    }

}


export default ShippingAddressForm;
