import Swal, {clickCancel} from 'sweetalert2';
class Notify {
    /**
     * @method notifyError
     * @memberof Notify
     * @static
     * @param {string} error
     *
     * @returns {void}
     */
    static notifyError(error) {
        Swal.fire('', error, 'error');
    }

    /**
     * @method notifySuccess
     * @member Notify
     * @static
     * @param {string} message
     *
     * @param reload
     * @param icon
     * @param endpoint
     * @returns {void}
     */
    static async notifySuccess(message = "Success", reload = false, icon = 'success', endpoint = '') {
        await Swal.fire('', message, icon);
        if (reload) {

            if (endpoint)
                window.location = endpoint;
            else
                window.location.reload();
        }
    }

    static notifyInfo(message, icon = 'info') {
        Swal.fire('', message, icon);

    }

    static showLoading() {
        Swal.showLoading()
    }

    static hideLoading() {
        Swal.clickCancel()
    }
}

export default Notify;
