
import {SET_CURRENT_USER, GET_USER_ERROR, GET_USER_PROCESSING} from './actions-types';


export const setCurrentUser = decoded => ({
    type: SET_CURRENT_USER,
    payload: decoded
});

export const setUserError = error => ({
    type: GET_USER_ERROR,
    payload: error,
})

export const setUserProcessing = () => ({
    type: GET_USER_PROCESSING,
})

export const logoutUser = () => (dispatch) => {
    localStorage.removeItem('authToken');
    dispatch(setCurrentUser({}));

};
