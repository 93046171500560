// A simple data API that will be used to get the data for our
// components. On a real website, a more robust data fetching
// solution would be more appropriate.
// eslint-disable-next-line no-unused-vars

const Plans = {

    plans: [
        {
            name: "paperback",
            original_price: 19.99,
            values: [
                {
                    id: 1,
                    name: "Fortnightly",
                    one_payment: 156,
                    recurring: 0,
                    books_inc: 12,
                },
                {
                    id: 2,
                    name: "Monthly",
                    one_payment: 90,
                    recurring: 15,
                    books_inc: 6,
                },
                {
                    id: 3,
                    name: "Bi Monthly",
                    one_payment: 51,
                    recurring: 0,
                    books_inc: 3,
                }
            ]
        },
        {
            name: "hardback",
            original_price: 24.99,
            values: [
                {
                    id: 11,
                    name: "Fortnightly",
                    one_payment: 192,
                    recurring: 0,
                    books_inc: 12,
                },
                {
                    id: 12,
                    name: "Monthly",
                    one_payment: 108,
                    recurring: 18,
                    books_inc: 6,
                },
                {
                    id: 13,
                    name: "Bi Monthly",
                    one_payment: 60,
                    recurring: 0,
                    books_inc: 3,
                }
            ]

        }
    ],
    types: function () {
        return this.plans.map(plan=>plan.name);
    },
    get_plans: function (plan_name) {

        return this.plans.find(plan=> plan_name===plan.name);

    }
};
window.plans = Plans.plans;
export default Plans;
// export default PlayerAPI;
